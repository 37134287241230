<template>
  <div>
   
    <v-container style="margin-bottom: 100px" >
      <div v-if="loading" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
      <v-row v-else>
        <v-col md="2"></v-col>
        <v-col md="4">
          <div class="student-buffet__card" v-if="card">
            <div class="student-buffer__heading-wrapper">
              <p class="student-buffet__heading">{{ $t('card number') }}</p>
            </div>
            <div class="student-buffet__value">
              <h3>{{ serial }}</h3>
            </div>
          </div>
        </v-col>
        <v-col md="4">
          <div class="student-buffet__card" v-if="card">
            <div class="student-buffer__heading-wrapper">
              <p class="student-buffet__heading">{{ $t('balance') }}</p>
            </div>
            <div class="student-buffet__value">
              <h3>{{ balance }}</h3>
            </div>
          </div>
        </v-col>
        
        <v-col md="12">
          <hr />
        </v-col>
        <v-col md="2" v-if="returnAbility('digital_wallet:store')">
          <app-add-card :userId="user_id" @cardAdded="fetchInfo" :cardStatus="card"></app-add-card>
        </v-col>
        <v-col md="2" v-if="returnAbility('digital_wallet_record:charge')">
          <app-pull-charge-balance :cardStatus="card" :text="$t('Charge')" type="charge" :cardId="cardId"
            :totalBalance="balance" @done="fetchInfo"></app-pull-charge-balance>
        </v-col>
        <v-col md="3" v-if="returnAbility('digital_wallet_record:pull')">
          <app-pull-charge-balance :cardStatus="card" :text="$t('Pull')" type="pull" :cardId="cardId"
            :totalBalance="balance" @done="fetchInfo"></app-pull-charge-balance>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import addCard from "./addCard.vue";
import pullChargeBalance from "./pullChargeBalance.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    appPullChargeBalance: pullChargeBalance,
    appAddCard: addCard,
  },
  data() {
    return {
      userId: this.$route.params.id,
      cardId: null,
      balance: null,
      serial: null,
      card: true,
      loading: false,
    };
  },
  methods: {
    ...mapMutations({
      setCardId: "buffet/setCardId",
    }),
    async fetchInfo() {
      try {
        this.loading = true;
        const response = await axios.get(`/digital-wallet/${this.userId}`);
        console.log("buffet res", response);
        if (response.data.data.card == false) {
          this.card = false;
          return;
        }
        this.card = true;
        this.balance = response.data.data.balance;
        this.serial = response.data.data.serial;
        this.cardId = response.data.data.id;
        // this.setCardId(response.data.data.id);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchInfo();
  },
};
</script>

<style>
.student-buffet__card {
  border: 1px solid #757575;
  border-radius: 20px;
  width: 150px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.student-buffer__heading-wrapper {
  text-align: center;
  width: 100%;
}

.student-buffet__heading {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #757575;
  width: 100%;
  margin: 0 !important;
  padding: 7px 0;
}

.student-buffet__value {
  margin: 10px 0;
}
</style>