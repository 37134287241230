<template>
    <div>
        <v-col md="12" class="py-5 mb-10">
          <v-row>
            <template v-for="(info, key, index) of childList">
              <v-col class="px-5" md="12" :key="index" v-if="info">
                <h3 class="ml-5">
                {{ $t(info.name) }} 
              </h3>
              </v-col>
            </template>
          </v-row>
        </v-col>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
       
      };
    },
    props: {
      childList: {
        type: Object,
      },
    },
    methods: {
     
    },
    created(){
      // console.log(this.childList);
    }
  };
  </script>
  
  <style></style>