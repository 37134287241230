<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <div v-if="loadingStatus" class="table__spinnerWrapper">
        <app-spinner class="mt-5 mr-5"></app-spinner>
      </div>
      <div v-else>
        <v-container>
          <v-row class="mr-5 pt-3">
            <v-col md="4" class="py-0">
              <p style="font-size: 2rem">
                {{ parent.name }}
              </p>
            </v-col>
            <v-col md="4" class="d-flex py-0">
              <div class="d-flex">
                <v-btn v-if="returnAbility('parent:update')" color="green" class="white--text ml-3" rounded
                  @click="$router.push(`/editUser/parent/${parentId}`)"> {{ $t("edit") }} </v-btn>
              </div>
            </v-col>
            <v-col md="3" class="d-flex justify-end py-0">
              <v-avatar size="100" class="ml-5">
                <v-img :src="parent.image" v-if="parent.image" @click="openPhoto(parent.image)"
                  style="cursor: pointer"></v-img>
                <v-icon v-else size="150"> mdi-alert-octagram </v-icon>
              </v-avatar>
            </v-col>
            <v-col md="12" class="py-0 mb-10">
              <v-row>
                <template v-for="(info, key, index) of parent.information">
                  <v-col md="4" :key="index" v-if="info.value">
                    <h3 class="ml-5">
                      {{ $t(info.desc) }} :
                      <span style="font-weight: 100">{{ info.value }}</span>
                    </h3>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card elevation="0" class="mt-5">
          <v-toolbar>
            <template v-slot:extension>
              <v-tabs v-model="tab" align-with-title color="#757575">
                <v-tabs-slider></v-tabs-slider>
                <v-tab :disabled="disableTabs" :href="'#' + '1'">{{ $t("child") }}</v-tab>
                <v-tab :disabled="disableTabs" :href="'#' + '2'" v-if="returnAbility('account:statement')"
                @click="$router.push(`/accountStatement/${parent.account_id}/${parent.sub_account}`);">
                  {{ $t("Account statement") }}
                </v-tab>
                <v-tab :disabled="disableTabs" :href="'#' + '3'" v-if="returnAbility('note:index')">
                  {{ $t("Digital Wallet") }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item value="1">
              <child :childList="parent.children" />
            </v-tab-item>
            <!-- <v-tab-item value="2">
            </v-tab-item> -->

            <v-tab-item value="3">
              <app-buffet v-if="tab == '3'"></app-buffet>

            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import buffet from "./parents/buffet/buffet.vue";
import child from "./parents/child.vue";

export default {
  components: {
    appBuffet: buffet,
    child,
  },
  data() {
    return {
      tab: this.$store.state.tabTeachers,
      parentId: this.$route.params.id,
      displayMainBuffetData: false,
      displayDepositBuffetData: false,
      displayPurchaseBuffetData: false,
    };
  },
  watch: {
    "tab": function () {
      this.$store.commit("SET_TABTEACHERS", this.tab);
    },
  },
  computed: {
    ...mapGetters({
      parent: "parents/getParentData",
      loadingStatus: "parents/getLoadingStatus",
    }),
    disableTabs() {
      if (this.getLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchParent: "parents/fetchParent",
    }),
  },
  created() {
    this.fetchParent(this.parentId);
    console.log(this.parent.children);
  },
};
</script>
<style scoped></style>
